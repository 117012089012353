import * as React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Layout from "../components/layout"
import { colors } from "../common/themeStyle"
import { SEO } from "../components/seo"
import { useLocation } from "@reach/router"

const Recruit = () => {
  const windowLocation = useLocation()
  return (
    <Layout>
      <SEO pageTitle="RECRUIT" pagePath={windowLocation.pathname} />
      <RecruitWrapper>
        <SubTitleWrapper>
          <SubTitle>世界をくまなく繋げよう</SubTitle>
          <Overview>
            くまちょんと一緒に
            <LessThanMediumBreake />
            リアルの架け橋を目指す仲間を大募集
          </Overview>
        </SubTitleWrapper>
        <RecruitContents>
          <Table>
            <tbody>
              <tr>
                <th>雇用形態</th>
                <td>【正社員】マネージャー</td>
              </tr>
              <tr>
                <th>仕事内容</th>
                <td>
                  くまちょんが世界に羽ばたくため、くまちょんの特徴をいかしたマネージメント業務
                </td>
              </tr>
              <tr>
                <th>応募資格</th>
                <td>くまちょんに興味があり、マネージメントの経験がある方</td>
              </tr>
              <tr>
                <th>待遇</th>
                <td>
                  <div>給与: 経験・能力等を考慮の上決定。季節により変動</div>
                  <div>
                    &emsp;&emsp;&ensp;春: タケノコ100本、夏: トカゲ700匹
                  </div>
                  <div>&emsp;&emsp;&ensp;秋: 鮭50本</div>
                  <div>手当: ラーメン手当</div>
                  <div>
                    休日: 土日祝日、夏季・年末年始休暇、休日勤務は代替日取得
                  </div>
                  <div>
                    勤務地: 東京都
                    <TableSentence>
                      (とある区にある、ちょっと下町情緒があふれるとあるビルの階段を31段登った所)
                    </TableSentence>
                  </div>
                </td>
              </tr>
              <tr>
                <th>選考</th>
                <td>書類選考 → 1次面接 → くまちょん面接 → オファー面談</td>
              </tr>
            </tbody>
          </Table>
          <Button
            onClick={() => {
              location.href = "contact"
            }}
          >
            エントリーフォームへ
          </Button>
        </RecruitContents>
        <RecruitContents>
          <Table>
            <tbody>
              <tr>
                <th>雇用形態</th>
                <td>【正社員】経理</td>
              </tr>
              <tr>
                <th>仕事内容</th>
                <td>株式会社くまちょんの経理業務全般</td>
              </tr>
              <tr>
                <th>応募資格</th>
                <td>
                  <div>日商簿記2級以上保持、税務についての知識がある方</div>
                  <div>くまちょんに興味がある方</div>
                </td>
              </tr>
              <tr>
                <th>待遇</th>
                <td>
                  <div>給与: 経験・能力等を考慮の上決定。季節により変動</div>
                  <div>
                    &emsp;&emsp;&ensp;春: タケノコ200本、夏: トカゲ500匹
                  </div>
                  <div>&emsp;&emsp;&ensp;秋: 鮭80本</div>
                  <div>手当: ラーメン手当</div>
                  <div>
                    休日: 土日祝日、夏季・年末年始休暇、休日勤務は代替日取得
                  </div>
                  <div>
                    勤務地: 東京都
                    <TableSentence>
                      (とある区にある、ちょっと下町情緒があふれるとあるビルの階段を31段登った所)
                    </TableSentence>
                  </div>
                </td>
              </tr>
              <tr>
                <th>選考</th>
                <td>書類選考 → 1次面接 → くまちょん面接 → オファー面談</td>
              </tr>
            </tbody>
          </Table>
          <Button
            onClick={() => {
              location.href = "contact"
            }}
          >
            エントリーフォームへ
          </Button>
        </RecruitContents>
      </RecruitWrapper>
    </Layout>
  )
}

export default Recruit

const SubTitleWrapper = styled.div`
  text-align: center;
  max-width: 530px;
  margin: auto auto 100px;
  padding-top: 80px;
  ${media.lessThan("medium")`
  margin: auto auto 30px;
  padding-top: 20px;
  `}
`
const SubTitle = styled.h1`
  font-weight: 400;
  font-size: 48px;
  text-align: center;
  margin-bottom: 30px;
  ${media.lessThan("medium")`
  font-size: 30px;
  `}
`
const Overview = styled.p`
  font-size: 18px;
  line-height: 1.7;
  letter-spacing: 0.05em;
  ${media.lessThan("medium")`
  font-size: 16px;
  `}
`
const RecruitWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 960px;
  padding: 0 1.0875rem 0;
`
const RecruitContents = styled.div`
  margin-bottom: 64px;
`
const Table = styled.table`
  max-width: 850px;
  font-size: 16px;
  margin: auto auto 64px;
  line-height: 1.7;
  letter-spacing: 0.1em;
  ${media.lessThan("medium")`
    font-size: 12px;
    line-height: 2.5;
    letter-spacing: 0.05em;
  `}
`
const TableSentence = styled.div`
  margin-left: 70px;
  ${media.lessThan("medium")`
    margin-left: 50px;
  `};
`
const Button = styled.button`
  letter-spacing: 2px;
  padding: 5px 50px;
  border: none;
  background-color: ${colors.gray};
  :hover {
    cursor: pointer;
  }
  ${media.lessThan("medium")`
    font-size: 12px;
  `}
`
const LessThanMediumBreake = styled.br`
  ${media.greaterThan("medium")`
    display: none;
  `}
`
